define("discourse/plugins/chat/discourse/components/chat/thread/threads-list-button", ["exports", "@glimmer/component", "@ember/routing", "discourse/helpers/concat-class", "discourse-common/helpers/d-icon", "I18n", "discourse/plugins/chat/discourse/components/chat/thread/header-unread-indicator", "@ember/template-factory", "@ember/component"], function (_exports, _component, _routing, _concatClass, _dIcon, _I18n, _headerUnreadIndicator, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  class ThreadsListButton extends _component.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "threadsListLabel", _I18n.default.t("chat.threads.list"));
    }
  }
  _exports.default = ThreadsListButton;
  _class = ThreadsListButton;
  (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      <LinkTo
        @route="chat.channel.threads"
        @models={{@channel.routeModels}}
        title={{this.threadsListLabel}}
        class={{concatClass
          "chat-threads-list-button"
          "btn"
          "btn-flat"
          (if @channel.threadsManager.unreadThreadCount "has-unreads")
        }}
      >
        {{icon "discourse-threads"}}
  
        <ThreadHeaderUnreadIndicator @channel={{@channel}} />
      </LinkTo>
    
  */
  {
    "id": "4A2Rx5g+",
    "block": "[[[1,\"\\n    \"],[8,[32,0],[[16,\"title\",[30,0,[\"threadsListLabel\"]]],[16,0,[28,[32,1],[\"chat-threads-list-button\",\"btn\",\"btn-flat\",[52,[30,1,[\"threadsManager\",\"unreadThreadCount\"]],\"has-unreads\"]],null]]],[[\"@route\",\"@models\"],[\"chat.channel.threads\",[30,1,[\"routeModels\"]]]],[[\"default\"],[[[[1,\"\\n      \"],[1,[28,[32,2],[\"discourse-threads\"],null]],[1,\"\\n\\n      \"],[8,[32,3],null,[[\"@channel\"],[[30,1]]],null],[1,\"\\n    \"]],[]]]]],[1,\"\\n  \"]],[\"@channel\"],false,[\"if\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat/thread/threads-list-button.js",
    "scope": () => [_routing.LinkTo, _concatClass.default, _dIcon.default, _headerUnreadIndicator.default],
    "isStrictMode": true
  }), _class);
});