define("discourse/plugins/chat/discourse/components/chat-drawer/threads/index", ["exports", "@glimmer/component", "@ember/service", "discourse-i18n", "discourse/plugins/chat/discourse/components/chat-drawer/header", "discourse/plugins/chat/discourse/components/chat-drawer/header/back-link", "discourse/plugins/chat/discourse/components/chat-drawer/header/right-actions", "discourse/plugins/chat/discourse/components/chat-drawer/header/title", "discourse/plugins/chat/discourse/components/user-threads", "@ember/template-factory", "@ember/component"], function (_exports, _component, _service, _discourseI18n, _header, _backLink, _rightActions, _title, _userThreads, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _class2;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let ChatDrawerThreads = _exports.default = (_class = (_class2 = class ChatDrawerThreads extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "appEvents", _descriptor, this);
      _initializerDefineProperty(this, "chat", _descriptor2, this);
      _initializerDefineProperty(this, "chatStateManager", _descriptor3, this);
      _initializerDefineProperty(this, "chatChannelsManager", _descriptor4, this);
      _defineProperty(this, "backLinkTitle", _discourseI18n.default.t("chat.return_to_list"));
    }
  }, (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      <ChatDrawerHeader @toggleExpand={{@drawerActions.toggleExpand}}>
  
        {{#if this.chatStateManager.isDrawerExpanded}}
          <div class="chat-drawer-header__left-actions">
            <div class="chat-drawer-header__top-line">
              <ChatDrawerHeaderBackLink
                @route="chat"
                @title={{this.backLink.title}}
              />
            </div>
          </div>
        {{/if}}
  
        <ChatDrawerHeaderTitle
          @title="chat.threads.list"
          @icon="discourse-threads"
          @channelName={{this.chat.activeChannel.title}}
        />
  
        <ChatDrawerHeaderRightActions @drawerActions={{@drawerActions}} />
      </ChatDrawerHeader>
  
      {{#if this.chatStateManager.isDrawerExpanded}}
        <div class="chat-drawer-content">
          <UserThreads />
        </div>
      {{/if}}
    
  */
  {
    "id": "KuGbXqK+",
    "block": "[[[1,\"\\n    \"],[8,[32,0],null,[[\"@toggleExpand\"],[[30,1,[\"toggleExpand\"]]]],[[\"default\"],[[[[1,\"\\n\\n\"],[41,[30,0,[\"chatStateManager\",\"isDrawerExpanded\"]],[[[1,\"        \"],[10,0],[14,0,\"chat-drawer-header__left-actions\"],[12],[1,\"\\n          \"],[10,0],[14,0,\"chat-drawer-header__top-line\"],[12],[1,\"\\n            \"],[8,[32,1],null,[[\"@route\",\"@title\"],[\"chat\",[30,0,[\"backLink\",\"title\"]]]],null],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n      \"],[8,[32,2],null,[[\"@title\",\"@icon\",\"@channelName\"],[\"chat.threads.list\",\"discourse-threads\",[30,0,[\"chat\",\"activeChannel\",\"title\"]]]],null],[1,\"\\n\\n      \"],[8,[32,3],null,[[\"@drawerActions\"],[[30,1]]],null],[1,\"\\n    \"]],[]]]]],[1,\"\\n\\n\"],[41,[30,0,[\"chatStateManager\",\"isDrawerExpanded\"]],[[[1,\"      \"],[10,0],[14,0,\"chat-drawer-content\"],[12],[1,\"\\n        \"],[8,[32,4],null,null,null],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"]],[\"@drawerActions\"],false,[\"if\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat-drawer/threads/index.js",
    "scope": () => [_header.default, _backLink.default, _title.default, _rightActions.default, _userThreads.default],
    "isStrictMode": true
  }), _class2), _class2), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "appEvents", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "chat", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "chatStateManager", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "chatChannelsManager", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
});