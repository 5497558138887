define("discourse/plugins/chat/discourse/components/chat/threads/index", ["exports", "@glimmer/component", "discourse-common/helpers/d-icon", "discourse-common/helpers/i18n", "discourse/plugins/chat/discourse/components/navbar", "discourse/plugins/chat/discourse/components/user-threads", "@ember/template-factory", "@ember/component"], function (_exports, _component, _dIcon, _i18n, _navbar, _userThreads, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  class ChatThreads extends _component.default {}
  _exports.default = ChatThreads;
  _class = ChatThreads;
  (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      <div class="chat-threads">
        <Navbar>
          <:current>
            {{icon "discourse-threads"}}
            {{i18n "chat.my_threads.title"}}
          </:current>
        </Navbar>
  
        <UserThreads />
      </div>
    
  */
  {
    "id": "hgSjn6pI",
    "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"chat-threads\"],[12],[1,\"\\n      \"],[8,[32,0],null,null,[[\"current\"],[[[[1,\"\\n          \"],[1,[28,[32,1],[\"discourse-threads\"],null]],[1,\"\\n          \"],[1,[28,[32,2],[\"chat.my_threads.title\"],null]],[1,\"\\n        \"]],[]]]]],[1,\"\\n\\n      \"],[8,[32,3],null,null,null],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[],false,[]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat/threads/index.js",
    "scope": () => [_navbar.default, _dIcon.default, _i18n.default, _userThreads.default],
    "isStrictMode": true
  }), _class);
});