define("discourse/plugins/chat/discourse/components/navbar/index", ["exports", "@glimmer/component", "@ember/object", "@ember/service", "@ember/template", "discourse/components/d-button", "discourse/lib/offset-calculator", "discourse/lib/url", "@ember/template-factory", "@ember/component"], function (_exports, _component, _object, _service, _template, _dButton, _offsetCalculator, _url, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _class2;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let ChatNavbar = _exports.default = (_class = (_class2 = class ChatNavbar extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "chatStateManager", _descriptor, this);
    }
    get topStyle() {
      return (0, _template.htmlSafe)(`top: ${(0, _offsetCalculator.headerOffset)()}px`);
    }
    async closeFullScreen() {
      this.chatStateManager.prefersDrawer();
      try {
        await _url.default.routeTo(this.chatStateManager.lastKnownAppURL);
        await _url.default.routeTo(this.chatStateManager.lastKnownChatURL);
      } catch (error1) {
        await _url.default.routeTo("/");
      }
    }
  }, (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      <div class="chat-navbar-container" style={{this.topStyle}}>
        <nav class="chat-navbar">
          {{#if (has-block "current")}}
            <span class="chat-navbar__current">
              {{yield to="current"}}
            </span>
          {{/if}}
  
          <ul class="chat-navbar__right-actions">
            <li class="chat-navbar__right-action">
              <DButton
                @icon="discourse-compress"
                @title="chat.close_full_page"
                class="open-drawer-btn btn-flat"
                @action={{this.closeFullScreen}}
              />
            </li>
          </ul>
        </nav>
      </div>
    
  */
  {
    "id": "PzwFVEnh",
    "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"chat-navbar-container\"],[15,5,[30,0,[\"topStyle\"]]],[12],[1,\"\\n      \"],[10,\"nav\"],[14,0,\"chat-navbar\"],[12],[1,\"\\n\"],[41,[48,[30,1]],[[[1,\"          \"],[10,1],[14,0,\"chat-navbar__current\"],[12],[1,\"\\n            \"],[18,1,null],[1,\"\\n          \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n        \"],[10,\"ul\"],[14,0,\"chat-navbar__right-actions\"],[12],[1,\"\\n          \"],[10,\"li\"],[14,0,\"chat-navbar__right-action\"],[12],[1,\"\\n            \"],[8,[32,0],[[24,0,\"open-drawer-btn btn-flat\"]],[[\"@icon\",\"@title\",\"@action\"],[\"discourse-compress\",\"chat.close_full_page\",[30,0,[\"closeFullScreen\"]]]],null],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"&current\"],false,[\"if\",\"has-block\",\"yield\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/navbar/index.js",
    "scope": () => [_dButton.default],
    "isStrictMode": true
  }), _class2), _class2), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "chatStateManager", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "closeFullScreen", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "closeFullScreen"), _class.prototype)), _class);
});